import React from "react"
import { Layout } from "../components/index"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Title } from "../components/index"
import "./booktemplate.scss"
import { Helmet } from "react-helmet"
import Favicon from "../img/favicon3.png"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types" //for images i think

export default function BookTemplate({
  pageContext: { book, title, slug },
  data,
}) {
  const SEOTitle = `${book.title} - knjiga Umjetničke organizacije Kreativna Kuća`

  return (
    <div className="book-template">
      <Helmet>
        <title>{SEOTitle}</title>
        <meta name="title" content={SEOTitle} />
        <meta name="description" content={book.longDescription} />
        <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Helmet>
      <Layout>
        <div className="book-template-wrapper">
          <Title title={title} subtitle={book.subtitle} />
          <div className="book-template-book-img-and-info">
            <Img
              className="book-template-book-img"
              fluid={book.cover.fluid}
              alt={book.title}
            />
            <div className="book-template-book-info">
              <div className="book-template-book-title">
                <b>Naslov:</b> {title}
              </div>
              <div className="book-template-book-award">
                <b>Nagrada:</b>{" "}
                {book.award
                  ? book.award.map(award => {
                      return <>{award.name}, </>
                    })
                  : " - "}
              </div>
              <div className="book-template-book-type">
                <b>Vrsta književne građe:</b> {book.type.type}{" "}
              </div>
              <div className="book-template-book-author">
                <b>Autorica:</b> {book.author.name}
              </div>
              <div className="book-template-book-illustrator">
                <b>Ilustrator:</b> {book.illustrator.name}
              </div>
              <div className="book-template-book-release-year">
                <b>Godina izdanja:</b> {book.yearOfPublication}
              </div>
              <div className="book-template-book-publisher">
                <b>Nakladnik:</b> {book.publisher.name}
              </div>
              <div className="book-template-book-page-number">
                <b>Broj stranica:</b> {book.pageNumber}
              </div>
              <div className="book-template-book-price">
                <b>Cijena:</b> {book.price} kn |{" "}
                {Math.round((book.price / 7.5345) * 100) / 100} €
              </div>
              <div className="book-template-book-availability">
                {book.availableForBuying
                  ? "Dostupno"
                  : "Prvo izdanje rasprodano, drugo u pripremi."}
              </div>
              {console.log(book.availableForBuying)}
              <Link to="/kontakt">
                <button className="book-template-book-add-to-cart-btn">
                  Kontaktirajte nas i naručite knjigu
                </button>
              </Link>
            </div>
          </div>
          <div className="book-template-book-long-description">
            <b>Opis djela:</b>
            {documentToReactComponents(book.longDescription.json)}
          </div>
        </div>
      </Layout>
    </div>
  )
}

export const data = graphql`
  query ImgQuery($title: String) {
    file(relativePath: { eq: $title }) {
      childImageSharp {
        fluid {
          base64
          sizes
          aspectRatio
          src
          srcSet
        }
      }
    }
  }
`
